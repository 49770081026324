<template>
    <v-row>
        <DialogDashboards :dialog="dialog" />
        <v-dialog v-model="dialog2.status" max-width="575" persistent>
            <v-card class="pa-5">
                <v-card-title class="text-h4">
                    <span class="mx-auto">{{dialog2.title}}</span>
                </v-card-title>
                <div class="text-center py-3">
                    <v-icon size="100" :color="dialog2.iconColor">{{dialog2.icon}}</v-icon>
                </div>
                <v-card-text class="text-h6 text-center">{{dialog2.msg}}</v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" elevation="0" class="px-10" @click="dialog2.status = false, get()">OK</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols="12" md="12">
            <v-card>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col class="d-none d-sm-block pl-2 pr-1 pl-lg-3">
                                <InputDatePicker
                                    :disabled="carregando"
                                    v-model="busca.dtinicio"
                                    label="Data inicial"
                                    :outlined="true"
                                    :dense="true"
                                />
                            </v-col>
                            <v-col class="d-none d-sm-block px-1">
                                <InputDatePicker
                                    :disabled="carregando"
                                    v-model="busca.dtfim"
                                    label="Data final"
                                    :outlined="true"
                                    :dense="true"
                                />
                            </v-col>
                            <v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
                                <v-sheet class="text-center" height="100%">
                                    <v-row class="pa-3">
                                        <v-col cols="6">
                                            <InputDatePicker
                                                :disabled="carregando"
                                                v-model="busca.dtinicio"
                                                label="Data inicial"
                                                :outlined="true"
                                                :dense="true"
                                            />
                                        </v-col>
                                        <v-col cols="6">
                                            <InputDatePicker
                                                :disabled="carregando"
                                                v-model="busca.dtfim"
                                                label="Data final"
                                                :outlined="true"
                                                :dense="true"
                                            />
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                text
                                                class="mt-n3"
                                                color="primary"
                                                @click="sheet = !sheet"
                                            >Cancelar</v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                text
                                                class="mt-n3"
                                                color="primary"
                                                @click="get(), sheet = !sheet"
                                            >Filtrar</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-bottom-sheet>
                            <v-col class="px-3 pl-sm-1 pr-lg-3 d-flex">
                                <v-autocomplete
                                    class="d-inline-flex d-sm-none"
                                    :items="filiaisFiltro"
                                    clearable
                                    item-text="filial"
                                    item-value="idfilial"
                                    label="Filial"
                                    outlined
                                    dense
                                    :disabled="carregando"
                                    v-model="busca.idfilial"
                                    append-outer-icon="mdi-menu"
                                    @click:append-outer="sheet = !sheet"
                                    @change="get()"
                                    hide-details
                                    required
                                ></v-autocomplete>
                                <v-autocomplete
                                    class="d-none d-sm-block"
                                    :items="filiaisFiltro"
                                    clearable
                                    item-text="filial"
                                    item-value="idfilial"
                                    label="Filial"
                                    outlined
                                    dense
                                    :disabled="carregando"
                                    v-model="busca.idfilial"
                                    @change="get()"
                                    hide-details
                                    required
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card color="primary" class="white--text">
                <v-progress-linear indeterminate absolute :active="carregando" />
                <v-card-title class="text-truncate">Unificador de Pedidos
                    <v-spacer/><v-btn  class="grey darken-1 white--text" @click="unificarPedido()" :disabled="selected.length == 0">Unificar</v-btn>
                </v-card-title>
                <v-card-text v-if="carregando">
                    <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
                </v-card-text>
                <v-card-text v-else>
                    <v-simple-table v-if="dados.lista" light dense class="rounded-0">
                        <thead>
                        <tr class="grey lighten-1">
                            <th class="pr-0">Selecionar</th>
                            <th class="text-center">Filial</th>
                            <th class="text-center">Nº Pedido</th>
                            <th class="text-center">Cód. Usuário</th>
                            <th class="text-center">Nome</th>
                            <th class="text-center">Quantidade SKUs</th>
                            <th class="text-center">Valor Custo</th>
                            <th class="text-center">Data Pedido</th>
                            <th class="text-center">Produtos</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(t, i) in dados.lista" :key="i">
                            <td width="80px" class="pr-0">
                                <v-checkbox  :disabled="t.idusario == 1400" class="ml-3 mb-n2 mt-2" v-model="selected" :value="t.idpedido"></v-checkbox>
                            </td>
                            <td class="text-center">{{t.idfilial }}</td>
                            <td class="text-center">{{t.idpedido }}</td>
                            <td class="text-center">{{t.idusario}}</td>
                            <td class="text-center">{{t.nomeusuario}}</td>
                            <td class="text-center">{{t.qtdsku}}</td>
                            <td class="text-center">{{t.valorpedido > 0 ? t.valorpedido : t.valortransferencia | formataDinheiro}}</td>
                            <td class="text-center">{{t.dtmovimento}}</td>
                            <td class="text-center">
                              <v-btn
                                  rounded="0"
                                  elevation="0"
                                  fab
                                  x-small
                                  class="primary"
                                  @click="getProdutos(t.idpedido)"
                              >
                                <v-icon>mdi-text-search</v-icon>
                              </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card-text v-else class="pa-0">
                        <v-alert type="error" elevation="0" icon="mdi-alert" class="ma-0" v-model="alerta.status">{{alerta.msg}}</v-alert>
                    </v-card-text>
                    <v-card-text class="pa-0">
                        <v-alert v-if="!alerta.status" type="error" elevation="0" icon="mdi-alert" class="ma-0 mt-4" v-model="alerta2">Selecione os pedidos para unificar</v-alert>
                    </v-card-text>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import DialogDashboards from "@/Components/Widgets/DialogDashboards.vue";

export default {
    name: "DashboardAguardando",
    mixins: [mixinFilial],
    components: {
        InputDatePicker,
        DialogDashboards,
    },
    data: () => ({
        dialog: { status: false, dados: [], header: {} },
        alerta2: false,
        dialog2: { status: false },
        alerta: { status: false, msg: ''},
        selected: [],
        dados: {},
        dadosProdutos: {},
        sheet: false,
        carregando: false,
        busca: {
            dtinicio: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-01`,
            dtfim: `${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getDate()}`,
            idfilial: 0,
            idpedido: 0,
        },
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
        filiaisFiltro() {
            if (this.filiais.lista) {
                return this.filiais.lista.map((v) => {
                    const filial = {};
                    filial.idfilial = v.idfilial;
                    filial.filial = `${v.idfilial} - ${v.filial}`;
                    return filial;
                });
            } else {
                return [];
            }
        },
    },
    methods: {
        unificarPedido(){
            this.carregando = true;
            let pedidos = null;
            if (this.selected.length > 0) {
                pedidos = this.selected.join(",").toString();
            } else {
                this.alerta2 = true
            }
            return axios
                .post(`${this.backendUrl}unificar/pedido`, {
                    idpedido: pedidos,
                    idfilial: this.busca.idfilial,
                })
                .then(() => {
                    this.alerta2 = false
                    this.carregando = false;
                    this.dialog2.status = true
                    this.dialog2.title = 'Unificação bem sucedida'
                    this.dialog2.msg = 'Os pedidos foram unificado com sucesso!'
                    this.dialog2.icon = 'mdi-check-circle-outline'
                    this.dialog2.iconColor = 'success'
                })
                .catch(() => {
                    this.dialog2.status = true
                    this.dialog2.title = 'Erro'
                    this.dialog2.msg = 'Não foi possível unificar pedidos!'
                    this.dialog2.icon = 'mdi-alert-outline'
                    this.dialog2.iconColor = 'red'
                    this.carregando = false;
                });
        },
        get() {
            this.selected = []
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}unificar/pedido/listar`, {
                    dtinicio: this.busca.dtinicio,
                    dtfim: this.busca.dtfim,
                    idfilial: this.busca.idfilial || 0,
                })
                .then((res) => {
                    this.dados = res.data;
                    this.carregando = false;
                    if (this.busca.idfilial == null){
                        this.alerta2 = false
                        this.alerta.status = true
                        this.alerta.msg = 'Informe a filial para realizar a busca'
                    } else if (this.dados.lista ==null) {
                        this.alerta2 = false
                        this.alerta.status = true
                        this.alerta.msg = 'Nenhum registro encontrado com o filtro informado'
                    }else{
                        this.alerta.status = false
                        this.alerta.msg = ''
                    }
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
        getProdutos(pedido) {
        this.selected = []
        this.carregando = true;
        return axios
            .post(`${this.backendUrl}unificar/pedido/produtos/listar`, {
              idfilial: this.busca.idfilial,
              idpedido: pedido,
            })
            .then((res) => {
              this.dadosProdutos = res.data.lista;
              this.dialog.dados = this.dadosProdutos;
              this.dialog.header = {
                idproduto: {nome: 'Código', tipo: 'texto'},
                produto: {nome: 'Descrição', tipo: 'texto'},
                qtdsolicitada: {nome: 'Qtd. Solicitada', tipo: 'texto'},
              };
              this.dialog.title = `Pedido: ${pedido}`;
              this.dialog.status = true;
              this.carregando = false;
              if (this.busca.idfilial == null){
                this.alerta2 = false
                this.alerta.status = true
                this.alerta.msg = 'Informe a filial para realizar a busca'
              } else if (this.dados.lista ==null) {
                this.alerta2 = false
                this.alerta.status = true
                this.alerta.msg = 'Nenhum registro encontrado com o filtro informado'
              }else{
                this.alerta.status = false
                this.alerta.msg = ''
              }
            })
            .catch(() => {
              this.carregando = false;
            });
      },
        async init() {
            this.busca.idfilial = this.usuario.idfilial;
            await this.get();
        },
    },
    watch: {
        "busca.dtfim": function () {
            this.get()
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtinicio = this.busca.dtfim;
            }
        },
        "busca.dtinicio": function () {
            this.get()
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtfim = this.busca.dtinicio;
            }
        },
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>
</style>